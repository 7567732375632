import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { Grid,Box,AspectImage,AspectRatio,Card,Button,Image,Text, Paragraph } from 'theme-ui'
import theme  from "../gatsby-plugin-theme-ui";

function Essays({data}){
    return(
        <Layout>
            <Grid  className="books-display-page" gap={2} columns={[2, null, 4]}>
                {data.allContentfulEssay.edges.map(({node}) =>{
                    return(
                        <Box>
                            <Link to={"/essays/" + node.slug}>
                                <Card className="essay-display">
                                    <img src={node.thumbnail.fixed.src} />
                                    <Paragraph>
                                        <Text>
                                            {node.title}
                                        </Text>
                                    </Paragraph>
                                </Card>
                            </Link>
                        </Box>
                    )
                })}
            </Grid>
        </Layout>
    )
}

export default Essays
export const query = graphql`
    query {
        allContentfulEssay {
            edges {
              node {
                slug
                title
                contentful_id
                thumbnail {
                  fixed{
                    src
                  }
                }
              }
            }
          }
    }
`